<template>
    <div class="Events">
        <!-- 轮播图 -->
        <van-swipe :autoplay="3000" :show-indicators="false">
            <van-swipe-item v-for="(item, index) in lunboList" :key="index">
                <img class="van-swipe-item-img" v-lazy="item.image" />
            </van-swipe-item>
        </van-swipe>

        <!-- Events -->
        <div class="Events-box w1330">
            <div class="Events-title text-align b800">IMPORTANT EVENTS</div>
            <div class="Events-list">
                <div class="Events-list-item flex pointer" v-for="item in elist" :key="item.id"
                    @click="$router.push(`/Detail?id=${item.id}`)">
                    <div class="Events-list-item-cover">
                        <img :src="item.image">
                    </div>
                    <div class="Events-list-item-right">
                        <div class="Events-list-item-title f-color3 f26 b600 oneline">
                            {{ item.title }}
                        </div>
                        <div class="Events-list-item-intro f-color3 f26 threeline">
                            {{ item.detail }}
                        </div>
                        <div class="Events-list-item-more flex align-center">
                            <span class="f-color3 f26">See more</span>
                            <img src="../assets/arrow-right-black.png">
                        </div>
                        <div class="Events-list-item-time flex flex-end">
                            <span class="f-color3 f26">{{ $api.timeForMat(item.createtime) }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 分页器 -->
            <pager @before="pageClick(-1)" @next="pageClick(1)"></pager>
        </div>
    </div>
</template>
  
<script>
import pager from '../components/pager.vue'

export default {
    name: 'Events',
    data() {
        return {
            lunboList: [],
            epage: 1,
            elimit: 10,
            elist: []
        }
    },
    components: {
        pager
    },
    created() {
        this.getLunbo()
        this.getEvents()
    },
    methods: {
        //获取背景轮播
        async getLunbo() {
            var res = await this.$api.Get('index/advertisement?position=introduce&page=1&limit=99999')
            this.lunboList = res.data
        },
        //事件列表
        async getEvents() {
            var res = await this.$api.Get(`index/importantEvents?position=incident&page=${this.epage}&limit=${this.elimit}`)
            this.elist = res.data
        },
        async pageClick(step) {
            var idx = this.epage + step
            if (idx < 1) {
                this.toast('没有更多数据')
            } else {
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    background: 'rgba(255, 255, 255, 0.7)'
                });
                var res = await this.$api.Get(`index/importantEvents?position=incident&page=${idx}&limit=${this.elimit}`)
                if (res.data.length) {
                    this.epage = idx
                    this.elist = res.data
                    this.scroll()
                } else this.toast('没有更多数据')
                loading.close();
            }
        },
        toast(message) {
            this.$message({
                type: 'warning',
                message,
                offset: 100
            });
        },
        scroll() {
            var dis = document.querySelector('.Events-box').offsetTop - document.querySelector('#navbar').clientHeight
            document.documentElement.scrollTop = dis
        }
    }
}
</script>
<style lang="less" scoped>
.van-swipe-item-img {
    display: block;
    width: 100%;
    height: calc(100vh - 71px);
    object-fit: cover;
}

.Events-title {
    padding: 90px 0 130px;
    line-height: 1;
    font-size: 75px;
    color: #f96f2f;
}

.Events-list-item:nth-of-type(n + 2) {
    margin-top: 110px;
}

.Events-list-item:hover {
    .Events-list-item-cover img {
        transform: scale(1.12);
    }

    .Events-list-item-title,
    .Events-list-item-intro {
        color: #f96f2f;
    }
}

.Events-list-item-cover {
    width: 315px;
    height: 243px;
    overflow: hidden;
}

.Events-list-item-cover img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .3s ease-in-out;
}

.Events-list-item-right {
    flex: 1;
    margin-left: 24px;
}

.Events-list-item-intro {
    margin: 30px 0 10px;
}

.Events-list-item-more img {
    margin-left: 10px;
    width: 7px;
    height: 12px;
}

.Events-list-item-time {
    margin-top: 10px;
}

@media screen and (max-width:750px) {
    .van-swipe-item-img {
        height: auto;
    }

    .Events-box {
        width: 300px;
    }

    .Events-title {
        padding: 35px 0 30px;
        font-size: 20px;
    }

    .Events-list-item:nth-of-type(n + 2) {
        margin-top: 28px;
    }

    .Events-list-item-cover {
        width: 96px;
        height: 79px;
    }

    .Events-list-item-right {
        margin-left: 8px;
    }

    .Events-list-item-title {
        font-size: 13px;
    }

    .Events-list-item-intro {
        margin: 4px 0;
        font-size: 12px;
    }

    .Events-list-item-more {
        display: none;
    }

    .Events-list-item-time {
        margin-top: 0;
        justify-content: flex-start;
    }

    .Events-list-item-time span {
        font-size: 9px;
    }

}
</style>
