<template>
    <div class="pager">
        <ul class="pages-limit flex justify-between">
            <li class="pages-limit-item pages-per flex align-center pointer" @click="$emit('before')">
                <img class="pages-icon" src="../assets/arrow-left-white.png">
                <span class="f-color1">上一页</span>
            </li>
            <li class="pages-limit-item pages-next flex align-center pointer" @click="$emit('next')">
                <span class="f-color1">下一页</span>
                <img class="pages-icon" src="../assets/arrow-left-white.png">
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    data() {
        return {}
    },
    props: {

    }
}
</script>
<style lang="less">
.pages-limit {
    padding: 100px 0 50px;
    // margin-top: 100px;
}

.pages-limit-item {
    padding: 20px 30px;
    background-color: rgba(0, 0, 0, .5);
    font-size: 30px;
}

.pages-limit-item:hover {
    background-color: rgba(0, 0, 0, .7);
}

.pages-icon {
    width: 15px;
}

.pages-per .pages-icon {
    margin-right: 20px;
}

.pages-next .pages-icon {
    margin-left: 20px;
    transform: rotate(180deg);
}

@media screen and (max-width:750px) {

    .pages-limit {
        padding: 24px 0 10px;
    }

    .pages-limit-item {
        padding: 8px 10px;
        font-size: 10px;
        cursor: none;
    }

    .pages-icon {
        width: 6px;
    }

    .pages-per .pages-icon {
        margin-right: 8px;
    }

    .pages-next .pages-icon {
        margin-left: 8px;
    }
}
</style>